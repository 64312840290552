import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ArgonDashboard from './plugins/argon-dashboard'
import store from './store/index'
import boot from "./boot/axios.js";
import VueCookies from 'vue-cookies'
Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.use(VueCookies)

Vue.prototype.$http = boot
new Vue({
  router,
  store,
  boot,
  render: h => h(App)
}).$mount('#app')
