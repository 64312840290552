import actions from "@/store/user/actions";
import mutations from "@/store/user/mutations";
import getters from "@/store/user/getters";
import Cookies from 'vue-cookies'

const token = Cookies.get('user_token') || null

const user = {
    state: {
        token: token,
        status: null,
        user_data: {},
    },
    namespaced: true,
    actions,
    getters,
    mutations,


}
export default user