import Vue from 'vue'
import Router from 'vue-router'
import routes from "@/router/routes";
import cookies from "vue-cookies";

Vue.use(Router)



const router = new Router({
  linkExactActiveClass: 'active',
  routes,
  mode: 'history',
  base: process.env.VUE_ROUTER_BASE
})

router.beforeEach((to, from, next) => {
  const token = cookies.get('user_token')
  if (!token) {
    if (to.name === 'signup' || to.name === 'login') {
      next()
    } else {
      next('/login')
    }
  } else {
    if (to.name === 'signup' || to.name === 'login' ) {
      next('/dashboard')
    } else {
      next()
    }
  }

})


export default router