import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";


const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('../views/Dashboard.vue')
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('../views/Orders/OrderList.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('../views/Icons.vue')
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('../views/UserProfile.vue')
            },
            {
                path: '/carriers',
                name: 'carriers',
                component: () => import('../views/Carriers/CarriersList')
            },
            {
                path: '/tables',
                name: 'tables',
                component: () => import('../views/Tables.vue')
            },
            {
                path: '/carrier-form/:id',
                name: 'carrier-form',
                component: () => import('../views/Carriers/CarrierForm.vue')
            },
            {
                path: '/users',
                name: 'user list',
                component: () => import('../views/users/UserList.vue')
            },
            {
                path: '/user-form/:id',
                name: 'user form',
                component: () => import('../views/users/UserForm')
            },
            {
                path: '/chat',
                name: 'chat',
                component: () => import('../views/Chat/ChatContainer')
            }
        ]
    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/Login.vue')
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/Register.vue')
            }
        ]
    }
]

export default routes
