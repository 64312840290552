<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  methods: {
    getAuthUserData() {
      this.$http.get('carriercalc/v1/me').then(res => {
        let {ID, display_name, user_email, user_login, user_registered} = res.data.data
        let user_data = {
          id: ID,
          display_name: display_name,
          user_email: user_email,
          user_login: user_login,
          user_registered: user_registered,
          user_meta: res.data.data.meta_fields ? res.data.data.meta_fields : null,
        }
        if (user_data.user_meta) {
          user_data.user_meta.working = Boolean(user_data.user_meta.working)
        }
        this.$store.dispatch('user/setUserData', user_data).then(() => {
          //логика для инита
        })
      })
    },
  },
  created() {
    if (this.$cookies.get('user_token')) {
      this.getAuthUserData()
    } else {
      this.$router.push('/login')
    }

  }
}
</script>
