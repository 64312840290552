import axios from 'axios'
import {api_url} from "../../.env.js";
import store from "@/store";
import VueCookies from 'vue-cookies'
import Router from 'vue-router'

let boot = axios.create({
    baseURL: api_url,
})

boot.interceptors.request.use(config => {
    if (store.state.user.token) {
        config.headers.Authorization = `Bearer ${store.state.user.token}`
    }

    return config

}, error => {
    return Promise.reject(error)
})

boot.interceptors.response.use(response => {

    return response

}, error => {
    if (error.response.status === 403) {
        store.commit('user/setToken', null);
        VueCookies.remove('user_token')
        Router.push('/login')
        return
    }

    const errorData = error.response.data || {}
    return Promise.reject(errorData || 'Неопределенная ошибка сервера')
})

export default boot
